import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import logo from '../images/Logo Restart new.png';
import {GiHamburgerMenu} from 'react-icons/gi';
import {VscChromeClose} from 'react-icons/vsc'
import arm from '../images/flag-arm.png';
import rus from '../images/flag-rus.png';
import { Link } from 'react-router-dom';


export default function Navbar() {
    const {t, i18n } = useTranslation();
    const [navbarState, setNavbarState] = useState(false);
    const CloseNavbar = () => {
      setNavbarState(false);
    };
    const changeLanguageAndCloseNavbar = (language) => {
      i18n.changeLanguage(language);
      setNavbarState(false); // Close the responsive navigation bar
    };
    
    return (
      <>
        <Nav>
          <div className="brand">
            <div className="container">
            <Link to='/'><img src={logo} alt="logo" /></Link>
              
            </div>
            <div className="toggle">
              {navbarState ? (
                <VscChromeClose onClick={() => setNavbarState(false)} />
              ) : (
                <GiHamburgerMenu onClick={() => setNavbarState(true)} />
              )}
            </div>
          </div>
  
          <ul>
            <li>
              <Link to='/'>{t("welcome")}</Link>
            </li>
            <li>
              <Link to='/mer_masin'>{t("about")}</Link>
            </li>
            <li>
              <Link to='/nergna'>{t("tur")}</Link>
            </li>
            <li>
              <Link to='/medicin'>{t("bjishk")}</Link>
            </li>
          </ul>
          <div className='btn'>
          <button onClick={() => i18n.changeLanguage('hy')}>
                <img className='lang' src={arm} alt="arm" />
          </button>
          <button onClick={() => i18n.changeLanguage('ru')}>
                <img className='lang' src={rus} alt="rus" />
          </button>
          </div>

        </Nav>
        <ResponsiveNav state={navbarState}>
          <ul>
          <li>
              <Link to='/'onClick={CloseNavbar}>{t("welcome")}</Link>
            </li>
            <li>
              <Link to='/mer_masin'onClick={CloseNavbar}>{t("about")}</Link>
            </li>
            <li>
              <Link to='/nergna'onClick={CloseNavbar}>{t("tur")}</Link>
            </li>
            <li>
              <Link to='/medicin'onClick={CloseNavbar}>{t("bjishk")}</Link>
            </li>
          </ul>
          <div className='btn-1'>
          <button onClick={() => changeLanguageAndCloseNavbar('hy')}>
                <img className='lang2' src={arm} alt="arm" />
          </button>
          <button onClick={() => changeLanguageAndCloseNavbar('ru')}>
                <img className='lang2' src={rus} alt="rus" />
          </button>
          </div>
        </ResponsiveNav>
      </>
    );
  }
  
  const Nav = styled.nav`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color:#ffffff;
    position:fixed;
      top:0;
      left:0;
      width:100%;
      height:5rem;
      z-index:10000;
      
   
    .brand {
      
      .container {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        font-size: 1.2rem;
        font-weight: 900;
        text-transform: uppercase;
        img {
            width:9rem;
            margin-left:3rem;
        }
      }
      .toggle {
        display: none;
        font-size:24px;
      }
    }
    ul {
      display: flex;
      gap: 1rem;
      list-style-type: none;
      margin:0 auto;
      li {
        a {
          text-decoration: none;
          color: black;
          font-size: 1.2rem;
          
          transition: 0.1s ease-in-out;
          &:hover {
            color: red;
            
            font-weight: 700;
          }
        }
       
      }
    }
    .lang{
      width:2rem;
      
    }
    .btn {
      display:flex;
      margin-right:2rem;
      button {
        cursor:pointer;
        margin-left:0.5rem;
        border:none;
        border-radius:2rem;
      a{
        
      }
        transition: 0.3s ease-in-out;
        &:hover {
          transform:scale(1.3);
        }
      }
    }
    @media screen and (min-width: 280px) and (max-width: 1080px) {
      .brand {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .toggle {
          display: block;
        }
        .container{
          img{
            width:5rem;
            margin-left:1rem;
          }
        }
      }
      ul {
        display: none;
      }
      button {
        display: none;
      }
      
    }
  `;
  
  const ResponsiveNav = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  right: ${({ state }) => (state ? "0" : "-100%")};
  top: 50px;
  background-color: white;
  height: 45vh;
  width: 100%;
  align-items: center;
  transition: right 0.5s ease-in-out;
    .lang2{
      width:1.5rem;
      
    }
    transition: 0.3s ease-in-out;
    ul {
      list-style-type: none;
      width: 100%;
      li {
        width: 100%;
        margin: 1rem 0;
        margin-left: 2rem;
        a {
          text-decoration: none;
          color: #778899;
          font-size: 1rem;
          transition: 0.1s ease-in-out;
          &:hover {
            color: #023e8a;
          }
        }
       
      }
    }
    .btn-1 {
      display:block;

      button {
        margin-right:3rem;
        border:none;
        border-radius:2rem;
      a{
        img{
          width:2rem;
        }
      }
        transition: 0.3s ease-in-out;
        &:hover {
          transform:scale(1.3);
        }
      }
    }
  `;
  
 
  