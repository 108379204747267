import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      hy:{
        translation: {
         welcome: "Գլխավոր",
         about:"Մեր գործակալության մասին",
         tur:"Ներգնա տուրեր",
         bjishk:"Բժշկական տուրիզմ",
         viza:"Վիզաների աջակցում",
         loer:"Իրավաբանական ծառայություններ",
         loerInfo1:"ընկերությունը առաջարկում է ծառայությունների լայն շրջանակ՝ ֆիզիկական և իրավաբանական անձանց համար: Ընկերության հմուտ մասնագետները խորհրդատվություն կտրամադրեն տարբեր իրավական հարցերով, իսկ հարկ եղած դեպքում կներկայացնեն ձեր շահերը դատական և այլ պետական մարմիններում:",
         loerInfo2:"Մենք կիրառում ենք պրոֆեսիոնալ և համապարփակ լուծումներ լավագույն աջակցությունը ցուցաբերելու համար անհատներին և կազմակերպություններին։Մենք առաջարկում ենք համալիր ծառայություններ՝ համագործակցելով տեղական և օտարերկրյա իրավաբանական, ֆինանսական, հաշվապահական և փորձագիտական կազմակերպությունների հետ։Մենք առաջարկում ենք որակյալ և արդյունավետ լուծումներ մատչելի պայմաններով։ Մենք հոգ ենք տանում ձեր իրավական խնդրի մասին՝ խնայելով ձեր ժամանակն ու միջոցները։",
         loerInfo3:"Մանրամասն տեղեկությունների համար զանգահարել հեռ. +37444 842844 կամ կարող եք այցելել մեզ Տիգրան Մեծ 18 պողոտա:",
         vizaInfo1:"Մեր փորձառու մասնագետները իրականացնում են վիզայի տրամադրման համար անհրաժեշտ փաստաթղթերի փաթեթի ճիշտ և մասնագիտական կազմումը` հաշվի առնելով յուրաքանչյուր հաճախորդի գործի առանձնահատկությունները և վիզա տրամադրող երկրի պահանջները` ազատելով ձեզ քաշքշուկներից և փաստաթղթային անճշտության պատճառով առաջացող հնարավոր բարդություններից:",
         vizaInfo2:`Կախված այցելության երկրների հյուպատոսական ծառայությունների պահանջներից դիմողի ներկայությունը կարող է լինել պարտադիր:Հայաստանի բոլոր քաղաքացիները պետք է ունենան վավեր վիզա՝ Շենգենյան գոտի մուտք գործելու համար՝ անկախ
                  մնալու տևողությունից և անկախ այն բանից, թե ինչ տեսակի ճամփորդական փաստաթուղթ ունեն՝ բացառությամբ վավեր կացության իրավունքի, որը նրանց թույլ է տալիս մնալ Շենգենյան գոտում:`,
         vizaInfo3:"Մենք առաջարկում ենք ձեզ",
         vizaInfo4:"Վիզա ստանալու լավագույն խորհրդատվական աջակցություն",
         vizaInfo5:"Բարձր որակավորում ունեցող աշխատակիցներ",
         vizaInfo6:"Անհատական մոտեցում յուրաքանչյուր հաճախորդին",
         vizaInfo7:"Որակի երաշխիք և անբիծ հեղինակություն",
         medicinInfo:"Բժշկական տուրիզմ Հայաստանում",
         medicinInfo1:`Բժշկական տուրիզմը - պրակտիկայի տերմին է,որը նշանակում է տրամադրել բժշկական ծառայությունների մատուցում մշտական բնակության վայրից դուրս  համատեղելով արտերկրում արձակուրդները և ձեռք բերելու 
                       բարձր որակավորում ունեցող բժշկական օգնություն:`, 
         about1:"Այսպիսով, մեր գործն է ստիպել ձեզ սիրահարվել աշխարհին...", 
         about2:"Մենք պարզապես ընկեր ենք ...",
         about3:"RESTART ընկերության արժեքներ են ...",
          pers1:`Հասկանալու համար, թե ով ենք մենք, պարզապես պետք է իմանալ, որ մեզ միավորում է սերը
                  ճամփորդել, ճանապարհորդել:
                  Մենք սիրում ենք նրան անվերջ:
                  Մենք սիրում ենք նրան անվերապահորեն:`,
          pers2:` Այսպիսով, մեր խնդիրն է ստիպել ձեզ սիրահարվել աշխարհին:
                  Այն ամենը, ինչ մենք անում ենք, անում ենք մեկ պատճառով ստիպել ձեզ սիրել
                  ճանապարհորդել այնքան, որքան սիրում ենք մենք :`,
          pers3:`Մենք պարզապես ընկեր ենք, ով ցանկանում է ձեզ ներկայացնել հաջորդը սերը
                  ձեր կյանքի:
                  Բայց զգույշ եղեք, երբ սիրահարվեք այս ընկերությանը, ոչ
                  չկա դեղամիջոց, որը կարող է բուժել այս սերը:
                  Նա հավերժ կմնա քո սրտում:
                  Եվ այդ ժամանակ մեր նպատակը կկատարվի։ RESTART ընկերության արժեքներ են`,
          
         pers4:`հոգատարություն և կարեկցանք`,
          pers5:`ջերմություն և սրտացավություն`,
          pers6:`նպատակասլացություն և նվիրվածություն`,
          pers7:`թիմային աշխատանք և անհատական ​​մոտեցում`,
          pers8:`արդյունավետություն և բարձր որակ`,
          pers9:`աշխատասիրություն և նախաձեռնողականություն`,
          rot1:`Մարիամ Մուշեղյան`,
          rot2:`Արտագնա տուր մենեջեր`,
          rot3:`Արամ Արաբյան`,
          rot4:`Գործադիր տնօրեն`,
          rot5:`Էդգար Ազատյան`,
          rot6:`Ներգնա տուրիզմի տնօրեն`,
          rot7:`Գևորգ Ղալթախչյան`,
          rot8:`Հիմնադիր տնօրեն`,
          rot9:`Աշոտ Սիմոնյան`,
          rot10:`Էքսկուրսիաների անվտանգության պետ`,
          days1:`3 օր լեռներում`,
          days2:`Արձակուրդ լեռներում 3 օր, 110.000դրամ երկուսի համար, մաքուր օդ, էկոլոգիապես մաքուր արտադրանք, յուրահատուկ բնություն և մեղմ կլիմա:`,
          restart:`RESTART ընկերությունը առաջարկում է միայն ամենահետաքրքիր տուրերը Հայաստանում:`,
          days4:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, համեղ ընթրիք, շշալցված ջուր:`,
          gegh1:`Գեղարդ, Գառնի, Քարերի սիմֆոնիա, Չարենցի կամար`,
          gegh2:`Այս շրջագայության մեջ ներառված Հայաստանում հայտնի տեսարժան վայրերը
                : Նախ կայցելեք Գառնիի հեթանոսական տաճար (1-ին դար)
                Նվիրված է արևի աստված Միտրային: Ճանապարհին կհիանաք 
                13-րդ դարի Գեղարդի վանական համալիրի շքեղությամբ,որը մասամբ քանդակված
                է և ներառվել համաշխարհային մշակութային ժառանգության ցանկում
                ՅՈՒՆԵՍԿՕ. Հաջորդ կետը կիրճն է՝ այսպես կոչված ՙբազալտ
                օրգան՚:`,
          gegh3:`Գնի մեջ ներառված է
                  հարմարավետ տրանսպորտ,
                  պրոֆեսիոնալ ուղեցույց,
                  մուտքի տոմսեր,
                  շշալցված ջուր:`,
          garni1:`Գառնի, Գեղարդ, Քարերի սիմֆոնիա, լավաշ թխելու գործընթացը`,
          garni2:`Այցելություն Գառնիի տաճար (1-ին դար). միակ ողջ մնացածը Հայաստանի տարածքում՝ հեթանոսության դարաշրջանին պատկանող հուշարձան։ Հետո կայցելեք Գեղարդի վանական համալիր, որը մասամբ քանդակված է լեռան վրա և ներառված է ՅՈՒՆԵՍԿՕ-ի համաշխարհային մշակութային ժառանգության ցանկում։ Հաջորդ կետը ձորն է՝ այսպես կոչված "Բազալտե երգեհոնը": Էքսկուրսիայի ավարտին հնարավորություն կունենաք դիտելու լավաշ (հայկական բարակ հաց) թխելու գործընթացը :`,
          gastro1:`Գաստրոնոմիական տուր Երևանում`,
          gastro2:`Գաստրոնոմիական շրջագայություն Հայաստանում՝ դուք կծանոթանաք Երևանի պատմությանը, կիմացաք, թե ինչպիսին էր քաղաքը ավելի քան հարյուր տարի առաջ: Սննդի զուգավորումները և հիանալի գինիները ձեզ ցույց կտա, թե ինչով է հայտնի Հայաստանը, քեզ սպասում է հայազգի գինիների և ուտեստների համտես, որից հետո դուք կհայտնվեք էյֆորիայի մեջ։`,
          gastro3:`Գնի մեջ ներառված է պրոֆեսիոնալ ուղեցույց, համեղ ընթրիք, գինու համտեսում :`,
          gyumri1:`Գյումրի (քաղաքային զբոսանք), Սև բերդ, Հառիճավանք`,
          gyumri2:`Այցելություն Հայաստանի երկրորդ ամենամեծ քաղաք Գյումրի, որը հանրապետության գլխավոր մշակութային կենտրոններից է։ Կանգառ կլինի Սև բերդի մոտ՝ ամենահայտնիներից մեկը քաղաքային հուշարձաննեից: Վերջին կանգառը վանական համալիրն "Հառիճավանք" է: Համալիրի ամենահին շենքը թվագրվում է 7-րդ դարով։`,
          gyumri3:`Գնի մեջ ներառված է պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, շշալցված ջուր:`,
          amr1:`Ամրակից, դենդրոպարկ, Լոռվա բերդ, Սաղմոսավանք`,
          amr2:`Առաջին կանգառը մոտակայքում գտնվող Սաղմոս-Սաղմոսավանք վանքում - դրամարկղային կիրճ է: Հաջորդ կանգառը Ստեփանավանի դենդրոպարկն է, որը միակն է իր տեսակի մեջ Հարավային Կովկասի տարածքում: Հաջորդ կետը Լոռվա բերդն է, որը հիմնադրվել է 10-րդ դարում և գտնվում է գետերի խորը կիրճերով պարսպապատված տարածքում։ Վերջին կանգառը Սուրբ Նիկոլաս Հրաշագործ եկեղեցին է (Ամրակից): Փոքր շենք է, որը նեոռուսականի հրաշալի օրինակ է ժամանակակից ճարտարապետության միտումներով։`,
          amr3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ (Mercedes Sprinter), պրոֆեսիոնալ ուղեցույց, շշալցված ջուր :`,
          zarni1:`Զառնի-Պառնի, Սանահին, Հաղպատ, Օձուն`,
          zarni2:`Շրջայցը սկսվում է Հաղպատի վանք այցելությամբ (10-րդ դար), որը ներառված է համաշխարհային ժառանգության օբյեկտների ցանկում ՅՈՒՆԵՍԿՕ: Երթուղու հետագա երկայնքով հիասքանչ քարանձավն է ամրոցային համալիր Զառնի-պարնին: Դրանից հետո դուք տեսնելու եք Ախթալա բերդը - հիմնադրված 10-ին դարում՝ որպես տիրակալների պաշտպանական ամրոցներից մեկը քյուրքյանների թագավորություններում: Վերջին՝ երթուղի-այցում Սանահինի վանք: Միջնադարյան հյուսիսային Հայաստանի մշակութային կենտրոնների գլխավորներից մեկը, կրկեսային համալիր թվագրված է 10-րդ դարով և ներառված աշխարհի ցուցակում ՅՈՒՆԵՍԿՕ-ի մշակութային ժառանգության օբյեկտների ցանկում։`,
          zarni3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ (Mercedes Sprinter), պրոֆեսիոնալ ուղեցույց, շշալցված ջուր :`,
          or0:`7-ՕՐՅԱ ՏՈՒՐ ՀԱՅԱՍՏԱՆՈՒՄ`,
          or1:`Օր 1. -- Հանդիպում օդանավակայանում, գալա ընթրիք, զբոսանք Երևանում։ (Գիշերակաց Երևանում)`,
          or2:`Օր 2. -- Նախաճաշ հյուրանոցում, ճամփորդություն Գառնիի տաճար, Քարերի սիմֆոնիա, այցելություն Գեղարդ վանք, լավաշ թխելու վարպետության դաս, ճաշ, ազատ ժամանակ, ընթրիք հյուրանոցում։ (Գիշերակաց Երևանում)`,
          or3:`Օր 3. -- Նախաճաշ հյուրանոցում, արշավ դեպի Խոր Վիրապի վանք, այցելություն Արենի գինու գործարան, գինու համտես, այցելություն Նորավանք, ճաշ, արշավ դեպի Տաթև, ընթրիք . հյուրանոցում (Գիշերակաց Տաթևում)`,
          or4:`Օր 4. -- Նախաճաշ հյուրանոցում, այցելություն 5 կմ երկարությամբ ամենաերկար ճոպանուղին (Wings Տաթև), ճամփորդություն դեպի Տաթևի վանք, Սատանի կամուրջ, ճոճվող կամուրջ և Խնձորեսկի քարանձավներ, ճաշ, այցելություն Ջերմուկ, Շաքեի ջրվեժ, ուղևորություն դեպի Սևան.։ Ընթրիք հյուրանոցում. (Գիշերակաց Սևանում)`,
          or5:`Օր 5. -- Նախաճաշ հյուրանոցում, արշավ դեպի Սևանի վանք, Սևանա լիճ, Դիլիջան, Պարզ լիճ: Հաղարծին վանք, ճաշ, տեղափոխում Իջևան, ջիփինգ սարերում, ջրվեժի լողավազան, ընթրիք լեռան գագաթին, գիշերակաց հյուրանոցում։ (Գիշերակաց Իջևանում)`,
          or6:`Օր 6. -- Նախաճաշ հյուրանոցում, այցելություն Սևան, Dargett գարեջրի գործարան, ճաշ, ուղևորություն Երևան, գիշերային զբոսանք Երևանում, հրաժեշտի ընթրիք ռեստորանում։ (Գիշերակաց Երևանում)`,
          or7:`Օր 7. -- Նախաճաշ հյուրանոցում: Ուղեկցում դեպի օդանավակայան։`,
          or8:`Արժեքը ներառում է Մոսկվա-Երևան-Մոսկվա չվերթը Տեղավորում հյուրանոցներում և հյուրատներում Օրական երեք սնունդ Ներքին շրջագայություններ Հայաստանում հարմարավետ տրանսպորտով. Ուղեկցող ուղեցույց. Պրոֆեսիոնալ լուսանկարչություն.`,
          ere1:`Շրջագայություն Երևանով, Էրեբունի թանգարանով և բերդով`,
          ere2:`Սկսեք ձեր ճանապարհորդությունը դեպի Հայաստան հետաքրքիր զբոսանքով Երեւանում, որը կոչվում է Հայաստանի սիրտ։ Հետաքրքիր պատմություններ մայրաքաղաքի հազարամյա պատմության և տեսարժան վայրերի մասին, գեղեցիկ ճարտարապետական ​​կոթողները ձեզ կզարմացնեն իրենցով պատմությունը։ Եվ դիտահարթակի բարձրությունից մինչև ձեր հայացքը կբացվի ամբողջ Երեւան քաղաքը:`,
          ere3:`Գնի մեջ ներառված է պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, շշալցված ջուր:`,
          tatev1:`Տաթևի վանք, Խնձորեսկ, Շաքիի ջրվեժ`,
          tatev2:`Շրջայցը սկսվում է Տաթևի վանք այցելությամբ, որը
          ժամանակին հոգևոր կենտրոն էր և ուներ ականավոր գիտական ​​և
          քաղաքական նշանակություն։ Վանք հասնելու համար պետք է
          զբոսնել աշխարհի ամենաերկար մարդատար ճոպանուղով -
          <<Տաթևի Թևեր>>, որոնց երկարությունը գրեթե 6 կմ է։Հաջորդը
          կետ Շաքիի ջրվեժը բացահայտում է իր ապշեցուցիչ գեղեցկությունը ձեր առջև: Ոչ պակաս հետաքրքիր կլինի այցը քարանձավային քաղաք
          Խնձորեսկ, որտեղ կարելի է քայլել ճոճվող կամրջով՝ փնտրելու համար
          ադրենալին.`,
          tatev3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, շշալցված ջուր:`,
          xorv1:`Խոր Վիրապ, Արենի գինու գործարան, Նորավանք`,
          xorv2:`Շրջագայությունը սկսվում է Խոր Վիրապի վանք այցելությամբ, որը գտնվում է բիբլիական Արարատ լեռան ստորոտին։ Այնուհետեւ հաջորդում է այցելություն Նորավանք, որը եզակի է իր կառուցվածքով, քանի որ եկեղեցին երկհարկանի վանք է, որին հաջորդում է գինու համտեսը "Արենի" գործարանում, որը հայտնի է իր գինիներով։`,
          xorv3:`Գնի մեջ ներառված է պրոֆեսիոնալ ուղեցույց, գինու համտեսում, շշալցված ջուր:`,
          dil1:`Սևան, Դիլիջան, Պարզ լիճ, Հաղարծին վանք`,
          dil2:`Այցելություն Հայաստանի «կապույտ մարգարիտ» Սևանա լիճ. այցելություն դեպի Սևանավանքը, որտեղից բացվում է լճի գեղեցիկ պատկերը, ծիածանագույն կապույտ, զմրուխտ և կանաչ գույնի ներկապնակով: Հետագա այցելություն Դիլիջան, որը կոչվում է նաև ՙՓոքրիկ Շվեյցարիա Հայաստանի ներսում՚ ֆանտաստիկ բնական անտառների համար. Դուք կարող եք ունենալ կանգառ 10-րդ դարի Հաղարծին վանքի մոտ, որը եղել է վերանորոգվել է Շեյխ Սուլթան Բին Մոհամմադի նվիրատվություններով: Շարժայի կառավարիչ Ալ-Քասիմին տպավորված էր վանքով իր այցելության ժամանակ:`,
          dil3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, շշալցված ջուր:`,
          shved1:`Փոքրիկ «Շվեյցարիան» Հայաստանում, Իջևանի լեռները, Սևանավանքը, Սևանա լիճը`,
          shved2:`Այցելություն Հայաստանի «կապույտ մարգարիտ» Սևանա լիճ. Թերակղզու բլրի վրա
                  վեհաշուք, կանգնեցվել է Սևանավանք վանքը, որտեղից երևում է
                  լճի պատկերը, որը փայլում է զմրուխտների և կանաչի ներկապնակի մեջ: Հաջորդը դուք
                  կայցելեք Դիլիջան, որը նաև կոչվում է <<Փոքրիկ Շվեյցարիա Հայաստանում>>
                  ֆանտաստիկ բնական անտառներ.  կանգառ կունենաք Հաղարծին վանքի մոտ
                  
                  (10-րդ դար),որը վերջերս վերանորոգվել է Շեյխ Սուլթան Բին Մոհամմադ ալ-ի նվիրատվություններով:
                  Շարժայի տիրակալ Քասիմին իր այցելության ժամանակ տպավորված էր վանքով։
                  
                  Դրանից հետո գնալու եք Իջեւան։ Քաղաքը գրավում է մարդկանց իր յուրահատկությամբ,
                  բնությունով և մեղմ կլիմանով:`,
          shved3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, շշալցված ջուր:`,
          jeep1:`Ջիփինգ լեռներում, ընթրիք լեռան գագաթին,քարանձավներ և ջրվեժներ`,
          jeep2:`Այս տուրի ընթացքում դուք հնարավորություն կունենաք
                 ամենագնացով շրջագայել լեռներով՝ ադրենալին փնտրելու համար: Դուք
                  մի քանի կանգառներ կունենաք ջրվեժների և քարանձավների մոտ, որոնք
                  ոգեշնչում են իրենց գեղատեսիլ տեսարաններով: Հաջորդը 
                  Լեռան գագաթին համեղ ընթրիքն է լինելու։`,
          jeep3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, համեղ ընթրիք, շշալցված ջուր:`,
          vino1:`Արենի գինու գործարան, Արենի քարանձավ, Խոր Վիրապ, Նորավանք`,
          vino2:`Շրջագայությունը սկսվում է Խոր Վիրապի վանք՝ Գեղեցիկ 
          վայրեր, որտեղից կարելի է հիանալ գեղատեսիլ տեսարաններով -
          Սուրբ Արարատ լեռը: Այնուհետև այցելեք Նորավանք,
          որն իր կառուցվածքով եզակի է: Դրանից հետո հաջորդում է 
          գինու համտեսը <<Արենի>> գինու գործարանում, որը հայտնի է
          իրենց գինիներով`,
          vino3:`Գնի մեջ ներառված է պրոֆեսիոնալ ուղեցույց, գինու համտեսում, շշալցված ջուր:`,
          dendr1:`Դենդրոպարկ, Սաղմոսավանք, Հայոց այբուբենի ծառուղի`,
          dendr2:`Առաջին կանգառը Ստեփանավանի դենդրոպարկը միակն է իր տեսակի մեջ Հարավային Կովկասի տարածքում , որը անտառից վերածված է դենդրոպարկի։ Հետո դուք կանգառ կունենաք Սաղմոսների վանքում: Սաղմոսավանքը գտնվում է Քասախի կիրճի մոտ։ Վերջում այցելելում ենք հայոց գրերի հուշարձան, որը գտնվում է Արագած լեռան արևելյան կողմի ստորոտին։`,
          dendr3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, համեղ ընթրիք, շշալցված ջուր:`,
          bunker1:`Գերմանացի գերիների բունկեր, գինու համտես, Սարդարապատ`,
          bunker2:`Այս շրջագայության շրջանակներում դուք կունենաք հետաքրքիր ճանապարհորդություն հին գինու և կոնյակի գործարան, որն իր ձևով եզակի շենք է, քանի որ գործարանը մասամբ կառուցվել է գերմանացի գերիների կողմից։`,
          bunker3:`Գնի մեջ ներառված է պրոֆեսիոնալ ուղեցույց, գինու և կոնյակի համտեսում,թեթև նախուտեստներ, շշալցված ջուր:`,
          konyak1:`Կոնյակի և գինու գործարան, տեսարժան վայրերի շրջագայություն, գինու համտեսում`,
          konyak2:`Այս շրջագայության շրջանակներում ձեզ սպասվում է հետաքրքիր ճամփորդություն դեպի հին գինու և կոնյակի գործարան:`,
          konyak3:`Գնի մեջ ներառված է հարմարավետ տրանսպորտ, պրոֆեսիոնալ ուղեցույց, մուտքի տոմսեր, տեսարժան վայրերի շրջագայություն, գինու համտեսում, շշալցված ջուր:`,
          destination:`Ամենաշատ այցելվող ուղղությունները`,
          africa:`Աֆրիկա`,
          evropa:`Եվրոպա`,
          asia:`Ասիա`,
          ayl:`Այլ ուղղություններ`,
          sharm:`"Շարմ Էլ Շեիխ"`,
          subt1:`Ավիատոմսեր,վիզա,հյուրանոց,All inclusive,նախաճաշ,տրանսֆեր`,
          duraction1:`7 գիշեր,1 անձի համար`,
          tel:`Հեռ.`,
          tunis:`Թունիս`,
          hurgada:`Հուրգադա`,
          sheis:`Սեյշելյան Կղզիներ`,
          subt2:`Ավիատոմսեր,վիզա,հյուրանոց 4*,նախաճաշ`,
          zanzibar:`Զանզիբար`,
          duraction2:`10 գիշեր,1 անձի համար`,
          subt3:`Ավիատոմսեր,հյուրանոց ,նախաճաշ,տրանսֆեր`,
          subt4:`Ավիատոմսեր,հյուրանոց 4*,նախաճաշ,կողմնակի ծախսեր վիզա+100$`,
          duraction3:`8 գիշեր,1 անձի համար`,
          duraction4:`15 գիշեր,1 անձի համար`,
          duraction5:`12 գիշեր,1 անձի համար`, 
          duraction6:`11 գիշեր,1 անձի համար`,
          milan:`Միլան`,
          hrom:`Հռոմ`,
          vienna:`Վիեննա`,
          praha:`Պրահա`,
          krete:`Կրետե կղզի Հունաստան`,
          larnaka:`Լառնակա Կիպրոս`,
          maldivner:`Մալդիվներ`,
          bali:`Բալի`,
          negombo:`Նեգոմբո Շրի Լանկա`,
          dabi:`Աբու Դաբի`,
          sharja:`Շարժա`,
          tailand:`Թաիլանդ Փուկետ`,
          kuba:`Կուբա`,
          cancun:`Մեքսիկա Քանքուն`,
          maya:`Մեքսիկա, Ռիվիեռա Մայա`,
          goback:`Վերադառնալ`,      


        }
    },
      ru: {
        translation: {
         welcome: "Главная",
         about:"О нашем агенстве ",
         tur:"Въездные туры",
         bjishk:"Медицинский туризм",
         viza:"Визовая поддержка",
         loer:"Юридические услуги",
         loerInfo1:"Компания предлагает широкий спектр услуг для физических и юридических лиц. Квалифицированные специалисты компании предоставят консультации по различным юридическим вопросам и при необходимости представят ваши интересы в суде и других государственных органах.",
         loerInfo2:"Мы применяем профессиональные и комплексные решения для оказания наилучшей поддержки физическим лицам и организациям.Мы предлагаем комплексные услуги в сотрудничестве с местными и зарубежными юридическими, финансовыми, бухгалтерскими и экспертными организациями.Мы предлагаем качественные и эффективные решения на доступных условиях. Мы берем на себя решение вашей юридической проблемы, экономя ваше время и деньги.",
         loerInfo3:"Для получения подробной информации звоните по тел. +37444 842844 или вы можете посетить нас по адресу пр. Тиграна Меца 18.",
         vizaInfo1:"Наши опытные специалисты осуществляют правильное и профессиональное составление пакета документов, необходимого для оформления визы, с учетом специфики дела каждого клиента и требований страны выдачи визы, избавляя Вас от хлопот и возможных осложнений, вызванных по неточностям в документах.",
         vizaInfo2:"В зависимости от требований консульских служб посещающих стран присутствие заявителя может быть обязательным.Все граждане Армении должны иметь действующую визу для въезда в Шенгенскую зону, независимо от срока пребывания и независимо от типа поездки документ, который они имеют, за исключением действительного права на проживание, которое позволяет им оставаться в Шенгенской зоне.",
         vizaInfo3:"Мы предлагаем вам",
         vizaInfo4:"  Лучшая  визовая  консультационная  поддержка",
         vizaInfo5:"Высококвалифицированные сотрудники",
         vizaInfo6:"Индивидуальный подход к каждому клиенту",
         vizaInfo7:"Гарантия качества и безупречная репутация",
         medicinInfo:"Медицинский туризм в Армении",
         medicinInfo1:`Медицинский туризм - термин практики, означающий оказание медицинских услуг вне места постоянного проживания, совмещающий отдых за границей и получение
                        высококвалифицированная медицинская помощь.`,
        about1:"Итак, наша работа заключается  в том, чтобы заставить вас влюбиться  в  мир...", 
        about2:"Мы всего лишь друзья...",
        about3:"Ценности компании RESTART - это...",
        pers1:`Что бы понять кто мы, нужно просто знать, что нас объединяет любовь
                к путешествиям.
                Мы любим её безгранично.
                Мы любим её безоговорочно.`,
        pers2:`Итак, Наша задача-заставить тебя влюбиться в мир.
                Всё, что мы делаем, мы делаем по одной причине, чтобы ты полюбил
                путешествовать так же сильно, как любим мы.`, 
        pers3:`Мы просто друг, который хочет познакомить тебя с очередной
                любовью твоей жизни.
                Но Будь осторожен, как только ты влюбишься в эту дружбу, нет
                никакого лекарства, способного излечить от этой любви.
                Она останется в твоём сердце навсегда.
                И вот тогда наша цель будет достигнута.RESTART — ценности компании`,
          pers4:`забота и сострадание`,
          pers5:`теплота и сердечность`,
          pers6:`целоустремлённость и преданность`,
          pers7:`командная работа и индивидуальный подход`,
          pers8:`эффективность и высшее качество`,
          pers9:`Трудолюбие и инициативность`,
          rot1:`Мариам Мушегян`,
          rot2:`Менеджер по выездному туризму`,
          rot3:`Арам Арабян`,
          rot4:`Исполнительный директор`,
          rot5:`Эдгар Азатян`,
          rot6:`Директор по въездному туризму`,
          rot7:`Геворг Галтахчян`,
          rot8:`Директор-основатель`,
          rot9:`Ашот Симонян`,
          rot10:`Начальник службы безопасности экскурсий`,
          days1:`3 дня в горах`,
          days2:`Отдых в горах на 3 дня, 110 000 драм на двоих, чистый воздух, экологически чистые продукты, уникальная природа и мягкий климат.`,
          restart:`Компания RESTART предлагает только самые интересные туры по Армении.`,
          days4:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, вкусный ужин, бутилированная вода.`,
          gegh1:`Гегард, Гарни, Симфония камней, Арка Чаренца`,
          gegh2:`Популярные достопримечательности Армении, включенные в этот тур
                . Первое посещение языческого храма Гарни (1 век)
                Посвящается богу солнца Митре. Вы будете поражены по пути
                С великолепием монастырского комплекса Гегард 13 века, который частично высечен
                и был включен в список всемирного культурного наследия
                ЮНЕСКО. Следующая точка – ущелье, так называемое «базальтовое».
                орган».`,
          gegh3:`Включено в цену
                  удобный транспорт,
                  профессиональный гид
                  входные билеты
                  бутилированная вода.`,
          garni1:`Гарни, Гегард, Симфония камней, выпечка лаваша`,
          garni2:`Посещение собора Гарни (1 век). единственный сохранившийся на территории Армении памятник, относящийся к эпохе язычества. Затем посетите монастырский комплекс Гегард, который частично высечен в горе и включен в список всемирного культурного наследия ЮНЕСКО. Следующая точка – каньон, так называемый «Базальтовый орган». В конце экскурсии у вас будет возможность посмотреть лаваш (армянские лепешки) процесс выпечки.`,
          gastro1:`Гастрономический тур в Ереване`,
          gastro2:`Гастрономический тур по Армении, вы познакомитесь с историей Еревана, узнаете, каким был город более ста лет назад. Гастрономические сочетания и отличные вина покажут вам, чем славится Армения, вас ждет дегустация армянских вин и блюд, после которой вы окажетесь в эйфории.`,
          gastro3:`В стоимость входит профессиональный гид, вкусный ужин и дегустация вин.`,
          gyumri1:`Гюмри (прогулка по городу), Сев Берд, Аричаванк`,
          gyumri2:`Посещение второго по величине города Армении Гюмри, который является одним из главных культурных центров республики. Остановка у Черной крепости, одного из самых известных памятников города. Последняя остановка – монастырский комплекс «Аричаванк». Самое старое здание комплекса датируется 7 веком.`,
          gyumri3:`Цена включает профессионального гида, входные билеты, воду в бутылках.`,
          amr1:`Амракиц, дендропарк, Лорийская крепость, Сагмосаванк`,
          amr2:`Первая остановка у монастыря Псалмов-Сагмосаванк, находящегося около
                ущелья кассах. Следующая остановка степанаванский дендропарк единственный
                в своём роде на территории южного Кавказа, который был превращен из еса в
                дендрарий. Следующий пункт Лорийская крепость, которая была основана в 10-м
                веке и располагалась на территории огражденной глубокими ущельями рек.
                Последняя остановка церковь Николая чудотворца(Амракиц). Это небольшое
                сооружение которое представляет собой замечательный образец неорусского
                направления архитектуры модерна.`,
          amr3:`В стоимость входит комфортабельный транспорт (Мерседес Спринтер), профессиональный гид, бутилированная вода.`,
          zarni1:`Зарни-Парни, Санаин, Ахпат, Одзун`,
          zarni2:`Тур начинается с посещения монастыря Ахпат(10 век),
                  который включён в список объектов всемирного наследия
                  
                  ЮНЕСКО. Далее по маршруту восхитительный пещерно-
                  крепостной комплекс Зарни-парни. После этого вы
                  
                  отправитесь осмотреть крепость Ахтала Основанный в 10-м
                  веке как одна из оборонительных крепостей правителей
                  царства Кюркидов. Последний по маршруту-посещение
                  монастыря Санаин. Один из главных средневековых
                  культурных центров северной Армении, цирковой комплекс
                  датировано к 10-му веку и включён в список всемирного
                  культурного наследия ЮНЕСКО.`,
          zarni3:`В стоимость входит комфортабельный транспорт (Мерседес Спринтер), профессиональный гид, бутилированная вода.`,
          or0:`7 ДНЕВНЫЙ ТУР ПО АРМЕНИИ`,
          or1:`День 1.

          Встреча в аэропорту, торжественный ужин, прогулка по еревану.
          
          (Ночёвка в Ереване)`,
          or2:`День 2.

          Завтрак в гостинице, поездка в храм Гарни, в Симфонию камней, визит в Гегардский
          монастырь, мастер-класс по выпечке лаваша, обед, свободное время, ужин в гостинице.
          
          (Ночёвка в городе Ереван)`,
          or3:`День 3.

          Завтрак в гостинице, поход в Хор-вирапский монастырь, визит в винный завод Арени,
          дегустация вин, визит в Нораванкский монастырь, обед, поход в Татев, ужин в
          гостинице.
          
          (Ночёвка в Татев)`,
          or4:`День 4.

          Завтрак в гостинице, визит на самую длинную канатную дорогу длиной в 5 км(Крылья
          Татева), поход в Татевский монастырь, на Чёртов мост, поход на качающийся мост и
          пещеры Хндзореска, обед, визит в Джермук, на водопад «Шаке», поездка в Севан.
          Ужин в гостинице.
          
          (Ночевка в Севане)`,
          or5:`День 5.

          Завтрак в гостинице, поход в Севанский монастырь, озеро Севан, Дилижан, Парз Лич.
          Монастырь Агарцин, обед, проезд в Иджеван, джиппинг по горам, водопад с
          бассейном, ужин на вершине горы, ночь в гостинице.
          (Ночевка Иджеване)`,
          or6:`День 6.

          Завтрак в гостинице, визит в Севан, на пивной завод Даргетт, обед, поездка в Ереван,
          прогулка по ночному Еревану, прощальный ужин в ресторане.
          (Ночевка в Ереване)`,
          or7:`День 7.
          Завтрак в гостинице. Проводы до аэропорта.`,
          or8:`В стоимость включен перелет Москва-Ереван-Москва
          Проживание в гостиницах и гостевых домах
          Трех разовое питание
          Внутренние переезды по Армении удобным транспортом.
          Гид-сопровождающий.
          Профессиональное фотографирование.`,
          ere1:`Экскурсия по Еревану, музей и крепость Эребуни`,
          ere2:`Начните свое путешествие по Армении с интересной прогулки по Еревану, который называют сердцем Армении. Интересные рассказы о тысячелетней истории столицы и достопримечательностях, красивые архитектурные памятники удивят вас своей историей. А с высоты смотровой площадки вашему взору откроется весь город Ереван.`,
          ere3:`Цена включает профессионального гида, входные билеты, воду в бутылках.`,
          tatev1:`Татевский Монастырь, Хндзореск, Шакинский водопад`,
          tatev2:`Тур начинается с посещения Татевского монастыря, который в свое
          время был духовным центром, имевшим выдающиеся научное и
          политическое значение. Чтобы дойти до монастыря вы должны
          прокатиться по самой длинной в мире пассажирской канатной дороге -
          <<Крылья Татева>>, длина которой составляет почти 6км. Следующий
          пункт водопад Шаки открывающий свою поразительную красоту перед
          вами. Не менее интересной станет посещение в пещерном городе
          Хндзореск, где вы сможете пройтись по качающемуся мосту в поисках
          адреналина.`,
          tatev3:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, бутилированная вода.`,
          xorv1:`Винный завод Арени, Пещера Арени, Хор Вирап, Нораванк`,
          xorv2:`Тур начинается с посещения монастыря Хор Вирап, Прекрасного
          места, откуда можно полюбоваться живописными видами на
          Священную гору Арарат. Затем осмотрите монастырь Нораванк,
          который уникален по своему строению, так как церковь Пресвятой
          Богородицы Двух этажный монастырь. После этого следует и
          дегустация вин на винном заводе << Арени>>, который известен
          своими винами`,
          xorv3:`В стоимость входит профессиональный гид, дегустация вин, вода в бутылках.`,  
          dil1:`Севан, Дилижан, озеро Парз, монастырь Агарцин`,
          dil2:`Посещение <<голубой жемчужины>> Армении озеро Севан.
          На холме полуострова величественное воздвигнут монастырь
          Севанаванк, откуда обнажается изумительная картина озера,
          переливающегося в палитре голубого, изумрудного и
          зелёного. Далее вы отправитесь в Дилижан, именуемый
          также <<Маленькая Швейцария в Армении>> за
          фантастические природные леса. У вас будет остановка
          около монастыря Агарцин 10-го века недавно
          отремонтированного на пожертвования шейха султана Бин
          Мохаммада аль-Касими, правителя Шарджи, который был
          впечатлен монастырём во время посещения.`,
          dil3:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, бутилированная вода.`,
          shved1:`Маленькая «Швейцария» в Армении, Иджеванские горы, Севанаванк, Озеро Севан`,
          shved2:`Посещение <<голубой жемчужины>> Армении озеро Севан. На холме полуострова
          величественное воздвигнут монастырь Севанаванк, откуда обнажается изумительная
          картина озера, переливающегося в палитре голубого, изумрудного и зелёного. Далее вы
          отправитесь в Дилижан, именуемый также <<Маленькая Швейцария в Армении>> за
          фантастические природные леса. У вас будет остановка около монастыря Агарцин 10-го
          
          века недавно отремонтированного на пожертвования шейха султана Бин Мохаммада аль-
          Касими, правителя Шарджи, который был впечатлен монастырём во время посещения.
          
          После вы отправитесь в Иджеван. Город привлекает людей своей неповторимой
          природой и мягким климатом.`,
          shved3:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, бутилированная вода.`,
          jeep1:`Джипинг по горам, ужин на вершине горы,пещеры и водопады`,
          jeep2:`В рамках этой экскурсии у вас будет возможность
                  прокатиться на джипах по горам в поисках адреналина. У вас
                  будет несколько остановок у водопадов и пещер, которые
                  вдохновляют своими живописными видами. Далее у вас
                  будет Вкуснейший Ужин на вершине горы.`,
          jeep3:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, вкусный ужин, бутилированная вода.`,
          vino1:`Винный завод Арени, Пещера Арени, Хор Вирап, Нораванк`,
          vino2:`Тур начинается с посещения монастыря Хор Вирап, Прекрасного
          места, откуда можно полюбоваться живописными видами на
          Священную гору Арарат. Затем осмотрите монастырь Нораванк,
          который уникален по своему строению, так как церковь Пресвятой
          Богородицы Двух этажный монастырь. После этого следует и
          дегустация вин на винном заводе << Арени>>, который известен
          своими винами.`,
          vino3:`В стоимость входит профессиональный гид, дегустация вин, вода в бутылках.`,  
          dendr1:`Дендрарий, Сагмосаванк, Аллея армянского алфавита`,
          dendr2:`Первая остановка Степанаванский дендропарк-единственный в своём
          роде На территории южного Кавказа который был превращен из леса
          в дендрарий. В парке расположен красивый деревянный мост.
          согласно традиции, загадав желание, проходя через мост в первый
          раз можно надеется на его скорое исполнение. Следующая остановка
          у монастыря Псалмов-Сагмосаванк, Находящегося около ущелья
          касах. Далее по маршруту посещение памятника армянского
          алфавита-красивый пример резьбы 39 Армянских букв из камня.
          Памятник. расположен у подножья восточной стороны горы Арагац, в
          селе Арташаван.`,
          dendr3:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, вкусный ужин, бутилированная вода.`,
          bunker1:`Бункер немецких военнопленных, дегустация вин, Сардарапат`,
          bunker2:`В рамках этой экскурсии вас ждет интересная поездка на старинный винно-коньячный завод, который является в своем роде уникальным сооружением, так как завод частично был построен пленными немцами.`,
          bunker3:`В стоимость входит профессиональный гид, дегустация вин и коньяков,лёгкие  закуски , бутилированная вода.`,
          konyak1:`Коньячно-винный завод, обзорная экскурсия, дегустация вин`,
          konyak2:`В рамках этой экскурсии вас ждет интересная поездка на старинный винно-коньячный завод`,
          konyak3:`В стоимость входит комфортабельный транспорт, профессиональный гид, входные билеты, обзорная экскурсия, дегустация вин, бутилированная вода.`,
          destination:`Самые посещаемые направления`,  
          africa:`Африка`,
          evropa:`Европа`,
          asia:`Азия`,
          ayl:`Другие направления`,
          sharm:`"Шарм-эль-Шейх"`,
          subt1:`Авиабилеты, виза, гостиница,All inclusive, завтрак, трансфер`,
          duraction1:`7 ночь, на 1 человека`,
          tel:`Тел.`,
          tunis:`Тунис`,
          hurgada:`Хургада`, 
          sheis:`Сейшельские острова`, 
          subt2:`Авиабилеты, виза, отель 4*, завтрак`,
          zanzibar:`Занзибар`,
          duraction2:`10 ночь, на 1 человека`, 
          subt3:`Авиабилеты, гостиница, завтрак, трансфер`, 
          subt4:`Авиабилеты, отель 4*, завтрак, непредвиденные расходы виза + 100$`,
          duraction3:`8 ночь, на 1 человека`, 
          duraction4:`15 ночь, на 1 человека`, 
          duraction5:`12 ночь, на 1 человека`, 
          duraction6:`11 ночь, на 1 человека`,
          milan:`Милан`,
          hrom:`Рим`,
          vienna:`Вена`,
          praha:`Прага`,
          krete:`Oстров Крит Греция`,
          larnaka:`Ларнака Кипр`,
          maldivner:`Мальдивы`,
          bali:`Бали `,
          negombo:`Негомбо Шри-Ланка`,
          dabi:`Абу Даби`,
          sharja:`Шарджа`,
          tailand:`Пхукет, Таиланд`,
          kuba:`Куба`,
          cancun:`Мексика Канкун`,
          maya:`Мексика, Ривьера Майя`,
          goback:`Возвращаться`,
          
         }
        },
    },
  });

export default i18n;
