import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logo from '../images/Logo Restart color.png';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const [scrollState, setScrollState] = useState(false);
  const location = useLocation();

  const toTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const handleScroll = () => {
      window.pageYOffset > 200 ? setScrollState(true) : setScrollState(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    toTop();
  }, [location]);

  return (
    <Totop scrollState={scrollState} onClick={toTop}>
      <img src={logo} alt="ghgd" />
    </Totop>
  );
}

const Totop = styled.div `
  display: ${({scrollState}) => (scrollState ? 'block' : 'none')};
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  z-index: 10;
  cursor:pointer;
  img {
    height: 2rem;
    border-radius: 1rem;
    filter:brightness(90%);
  }

`;