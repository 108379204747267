import React, { useEffect, lazy, Suspense } from "react";
import { HashRouter, Route, Routes } from 'react-router-dom';

import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import scrollreveal from "scrollreveal";

const Hero = lazy(() => import("./components/Hero"));
const Recommend = lazy(() => import("./components/Recommend"));
const Testimonials = lazy(() => import("./components/Testimonials"));
const Services = lazy(() => import("./components/Services"));
const Footer = lazy(() => import("./components/Footer"));
const Slider = lazy(() => import("./components/Slider/Slider"));
const CardRotate = lazy(() => import("./components/CardRotate"));
const CardList = lazy(() => import("./components/CardList"));
const SmallShvecaria = lazy(() => import("./components/ArmenianTours/SmallShvecaria"));
const Vino = lazy(() => import("./components/ArmenianTours/Vino"));
const TreeDays = lazy(() => import("./components/ArmenianTours/TreeDays"));
const GarniGeghard = lazy(() => import("./components/ArmenianTours/GarniGeghard"));
const Bunker = lazy(() => import("./components/ArmenianTours/Bunker"));
const Tarer = lazy(() => import("./components/ArmenianTours/Tarer"));
const Dilijan = lazy(() => import("./components/ArmenianTours/Dilijan"));
const XorVirap = lazy(() => import("./components/ArmenianTours/XorVirap"));
const Jeeping = lazy(() => import("./components/ArmenianTours/Jeeping"));
const Medicin = lazy(() => import("./components/Medicin"));
const Viza = lazy(() => import("./components/Viza"));
const Loerr = lazy(() => import("./components/Loerr"));
const PersInfo = lazy(() => import("./components/PersInfo"));
const Map = lazy(() => import("./components/Map"));
const Tatev = lazy(() => import("./components/ArmenianTours/Tatev"));
const Konyak = lazy(() => import("./components/ArmenianTours/Konyak"));
const Garn = lazy(() => import("./components/ArmenianTours/Garn"));
const ResponsiveVideo = lazy(() => import("./components/ResponsiveVideo"));
const Yerevan = lazy(() => import("./components/ArmenianTours/Yerevan"));
const Gyumri = lazy(() => import("./components/ArmenianTours/Gyumri"));
const Gastro = lazy(() => import("./components/ArmenianTours/Gastro"));
const Amrakic = lazy(() => import("./components/ArmenianTours/Amrakic"));
const Zarni = lazy(() => import("./components/ArmenianTours/Zarni"));
const Hayastanum = lazy(() => import("./components/ArmenianTours/Hayastanum"));
const InfoSlider = lazy(() => import("./components/InfoSlider"));

 function App() {
  useEffect(() => {
    const sr = scrollreveal({
      origin: "top",
      distance: "50%",
      duration: 2000,
      reset: false,
    });
    sr.reveal(
      `
      nav,
      #services,
      #recommend,
      #testimonials,
      FooterContainer
      `,
      {
        opacity: 0,
        interval: 300,
      }
    );
  }, []);

  return (
    <HashRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Slider />
            <Services />
            <ResponsiveVideo />
            <Hero />
            <Recommend />
            <Testimonials />
          </Suspense>
        } />

        <Route path="/medicin" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Medicin />
          </Suspense>
        } />
        <Route path="/mer_masin" element={
          <Suspense fallback={<div>Loading...</div>}>
            <InfoSlider />
            <CardRotate />
            <PersInfo />
            <Map />
          </Suspense>
        } />

        <Route path="/loer" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Loerr />
          </Suspense>
        } />
        <Route path="/viza" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Viza />
          </Suspense>
        } />
        <Route path="/nergna" element={
          <Suspense fallback={<div>Loading...</div>}>
            <CardList />
          </Suspense>
        } />
        <Route path="/sweedon" element={
          <Suspense fallback={<div>Loading...</div>}>
            <SmallShvecaria />
          </Suspense>
        } />
        <Route path="/vino" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Vino />
          </Suspense>
        } />
        <Route path="/treeDays" element={
          <Suspense fallback={<div>Loading...</div>}>
            <TreeDays />
          </Suspense>
        } />
        <Route path="/geghard" element={
          <Suspense fallback={<div>Loading...</div>}>
            <GarniGeghard />
          </Suspense>
        } />
        <Route path="/bunker" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Bunker />
          </Suspense>
        } />
        <Route path="/tarer" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Tarer />
          </Suspense>
        } />
        <Route path="/dilijan" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Dilijan />
          </Suspense>
        } />
        <Route path="/xorvirap" element={
          <Suspense fallback={<div>Loading...</div>}>
            <XorVirap />
          </Suspense>
        } />
        <Route path="/jeeping" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Jeeping />
          </Suspense>
        } />
        <Route path="/tatev" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Tatev />
          </Suspense>
        } />
        <Route path="/konyak" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Konyak />
          </Suspense>
        } />
        <Route path="/garn" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Garn />
          </Suspense>
        } />
        <Route path="/yerevan" element={
          <Suspense fallback={<div>Loading...</div>}>             
            <Yerevan />
          </Suspense>
        } />
        <Route path="/gyumri" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Gyumri />
          </Suspense>
        } />
        <Route path="/gastro" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Gastro />
          </Suspense>
        } />
        <Route path="/amrakic" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Amrakic />
          </Suspense>
        } />
        <Route path="/zarni" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Zarni />
          </Suspense>
        } />
        <Route path="/hayastanum" element={
          <Suspense fallback={<div>Loading...</div>}>
            <Hayastanum />
          </Suspense>
        } />
      </Routes>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </HashRouter>
  );
}

export default App;
